import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c1d78e7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container auditImages"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "text-area"
};
const _hoisted_4 = {
  class: "task-name"
};
const _hoisted_5 = {
  class: "task-name1"
};
const _hoisted_6 = {
  class: "text-item"
};
const _hoisted_7 = {
  class: "text-item"
};
const _hoisted_8 = {
  class: "text-item"
};
const _hoisted_9 = {
  class: "text-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_lazy = _resolveDirective("lazy");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.imageData, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "image-box",
      key: index
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.auditList.imgLst, img => {
      return _withDirectives((_openBlock(), _createElementBlock("img", {
        key: img,
        alt: "",
        class: "img",
        onClick: $event => $options.preview(img)
      }, null, 8, _hoisted_2)), [[_directive_lazy, img]]);
    }), 128)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.auditList.taskTitle), 1), _createElementVNode("div", _hoisted_5, "任务：" + _toDisplayString(item.auditList.topic), 1), _createElementVNode("div", _hoisted_6, "提交日期：" + _toDisplayString(item.auditList.uploadDate || '-'), 1), _createElementVNode("div", _hoisted_7, "审核日期：" + _toDisplayString(item.auditList.auditDate || '-'), 1), _createElementVNode("div", _hoisted_8, "触  点：" + _toDisplayString(item.auditList.pgName || '-'), 1), _createElementVNode("div", _hoisted_9, "审 核 人：" + _toDisplayString(item.auditList.auditor || '-'), 1)])]);
  }), 128)), _createElementVNode("div", {
    class: "back-btn",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
  }, "返回")]);
}