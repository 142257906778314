import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import { terminalImgDetail } from '@/api/wxwork';
export default {
  data() {
    return {
      imgSrc: 'https://img.yzcdn.cn/vant/apple-2.jpg',
      imageData: []
    };
  },
  mounted() {
    document.title = this.$route.query.taskName || '审核结果';
    this.getData();
  },
  methods: {
    getData() {
      let {
        week,
        terminalCode,
        taskCode,
        planCodeList
      } = this.$route.query;
      console.log(this.$route.query);
      if (typeof planCodeList === 'string') {
        planCodeList = [planCodeList];
      }
      terminalImgDetail({
        week,
        terminalCode,
        taskCode,
        planCodeList: planCodeList
      }).then(res => {
        if (res.data.status) {
          this.imageData = res.data.data;
          document.title = (this.$route.query.taskName || '审核结果') + `（${res.data.total || 0}张）`;
        }
      });
    },
    preview(src) {
      _ImagePreview([src]);
    }
  }
};